// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "l_pK d_gt d_cr";
export var heroHeaderCenter = "l_gv d_gv d_cr d_dv";
export var heroHeaderRight = "l_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "l_pL d_gq d_cv";
export var heroParagraphCenter = "l_gr d_gr d_cv d_dv";
export var heroParagraphRight = "l_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "l_pM d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "l_pN d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "l_pP d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "l_pQ d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "l_pR d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "l_pS r_pS";
export var heroExceptionNormal = "l_pT r_pT";
export var heroExceptionLarge = "l_pV r_pV";
export var Title1Small = "l_pW r_pW r_qS r_qT";
export var Title1Normal = "l_pX r_pX r_qS r_qV";
export var Title1Large = "l_pY r_pY r_qS r_qW";
export var BodySmall = "l_pZ r_pZ r_qS r_q9";
export var BodyNormal = "l_p0 r_p0 r_qS r_rb";
export var BodyLarge = "l_p1 r_p1 r_qS r_rc";